import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      logo: file(relativePath: { eq: "dte_logo.png" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const headerItems = [
    { link: '/', label: 'Sākums' },
    { link: '/juridiskie-pakalpojumi', label: 'Juridiskie Pakalpojumi' },
    // { link: '/seminari', label: 'Semināri' },
    { link: '/par-dianu', label: 'Par Diānu' },
    { link: '/kontakti', label: 'Kontakti' },
  ]

  return (
    <header className="container mt-6">
      <div className="flex items-start justify-between -mx-2">
        <Link to="/" className="px-2">
          <Img
            fixed={data.logo.childImageSharp.fixed}
            title={data.site.siteMetadata.title}
            style={{ display: 'block' }}
          />
        </Link>
        <div className="px-2">
          <ul className="sm:flex flex-wrap justify-end text-right font-medium -mx-2">
            {headerItems.map((headerItem) => (
              <li className="relative px-2 uppercase" key={headerItem.link}>
                <Link
                  to={headerItem.link}
                  activeClassName="active-link underline sm:no-underline"
                >
                  {headerItem.label}
                </Link>
                <span className="active-link-mark -mb-1 absolute bottom-0 left-0 right-0 hidden justify-center">
                  <span className="w-1 h-1 bg-brand-gray2 rounded-full"></span>
                  <span className="w-1 h-1 bg-brand-gray2 rounded-full ml-1"></span>
                  <span className="w-1 h-1 bg-brand-gray2 rounded-full ml-1"></span>
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header
