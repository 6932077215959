import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Facebook as FacebookIcon } from '@icons-pack/react-simple-icons'
import { MailIcon } from '@heroicons/react/solid'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          facebook
          email
        }
      }
    }
  `)

  return (
    <footer className="bg-brand-brown text-white font-medium">
      <div className="container bg-brand-brown text-white py-4 flex flex-col items-center">
        <ul className="flex items-center -mx-2 mb-4">
          <li className="px-2">
            <a
              href={data.site.siteMetadata.facebook}
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon className="h-8 w-8" />
            </a>
          </li>
          <li className="px-2">
            <a href={`mailto:${data.site.siteMetadata.email}`}>
              <MailIcon className="h-8 w-8" />
            </a>
          </li>
        </ul>
        <p>© Diāna Grīnberga</p>
        <p>Visas tiesības aizsargātas.</p>
      </div>
    </footer>
  )
}

export default Footer
