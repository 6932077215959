import React from 'react'
import PropTypes from 'prop-types'

import '@fontsource/oswald/300.css' /** light */
import '@fontsource/oswald/500.css' /** medium */
import '@fontsource/oswald/700.css' /** bold */
import './main.css'

import Header from './header'
import Footer from './footer'

const Layout = ({ children }) => {
  return (
    <div className="font-sans font-light antialiased text-gray-900">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
